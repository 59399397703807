import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withAppContext } from 'src/store/initAppContext'
import SlotGameSEOFun88 from './SlotGameSEOFun88'
import SlotGameSEOYolo from './SlotGameSEOYolo'

type Props = {

}

const SlotGameSEO = (props: any) => {

  const { app: { brandId } } = props;
  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247club.com';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.iw247.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
  } else if (brandId === 40) {
    domainURL = 'https://www.fomo7.xyz';
  }
  else if (brandId === 46) {
    domainURL = 'https://www.fun88.site';
  }
  else if (brandId === 47) {
    domainURL = 'https://www.gofun88.in';
  }

  return (
    (brandId === 33 || brandId === 40 || brandId === 46) ? <></> :
    (brandId === 31 || brandId === 32) ? <SlotGameSEOYolo /> :
    (brandId === 47) ? <SlotGameSEOFun88 /> :
    <SlotGameSEOYolo />
  )
}

export default withAppContext(SlotGameSEO);