import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { Helmet } from "react-helmet";
import styled, { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import { theme } from 'styled-tools';
const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/teenpatti/`,
                    "name": "Bet on teenpatti",
                    "position": 2
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/teenpatti/TEENPATTI2020`,
                    "name": "teenpatti 2020",
                    "position": 3
                }],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

const Teenpatti2020 = (props) => {

    const { app: { brandId } } = props;
   
    let domainURL = 'https://www.yolo247.site';

    if(brandId === 32) {
        domainURL = 'https://www.yolo247club.com';
    }
    else if(brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if(brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } 
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }


    const domainName =(brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }

                </script>
            </Helmet>
 <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>Teenpatti 2020: Play The Exciting Card Game on {domainName}</h1></label>
                    <div className="accordion__content">
                        <h2>Teenpatti 2020: Play The Exciting Card Game on {domainName}</h2>
                        <p>Teenpatti 2020 is a digital adaptation of the traditional South Asian card game. The game started taking the online casino industry by storm by bringing the classic Indian card game to life with stunning visuals. The game has also captured the hearts of players worldwide due to its easy gameplay and rules.</p>
                        <p>Teenpatti 2020 is a casino card game which is played with a regular deck of 52 cards, and the objective is to hold the high-ranking cards. Excited to play this game on the {domainName} platform? Then continue reading to learn more about the game features, payouts, and betting options before placing any bet with real money online.</p>

                        <h2>How to Start Playing Teenpatti 2020 on {domainName}?</h2>
                        <p>Follow these simple steps to start playing at {domainName} and experience the excitement of the card game:</p>
                        <ol>
                            <li><h3>Create an Account:</h3> Visit the {domainName} website and click the Signup button to create your account.</li>
                            <li><h3>Make a Deposit:</h3> After you have made an account, it is time to deposit money. Log in to your {domainName} account and navigate to the "Deposit" section. To make a deposit, choose your payment option and follow the steps.</li>
                            <li><h3>Select Teenpatti 2020:</h3> Visit the Teenpatti section of {domainName} and search for Teenpatti 2020. Launch the game by clicking its icon, and get ready to experience Teenpatti at its absolute best.</li>
                            <li><h3>Place Your Bets:</h3> Now that you are ready to play, it's time to place your bets. Decide whether you want to bet on Player A or B. Enter the desired bet amount and click on the corresponding area on the virtual table. The dealer will begin dealing cards when all players have put in their wagers.</li>
                            <li><h3>Withdraw Your Winnings:</h3> You can easily withdraw your winnings from your {domainName} account. Select your preferred withdrawal method in the "Withdrawal" section.</li>
                        </ol>

                        <h2>Features of Teenpatti 2020 available on {domainName}</h2>
                        <p>While playing the game on {domainName}, you may get various features and offers that include:</p>
                        <ul>
                            <li>Bet Statistics: View the total wagers placed and the number of bettors.</li>
                            <li>Bet Limits: Know the minimum and maximum bet limits at the table.</li>
                            <li>Traffic Light: Track the status of betting time with green, orange, and grey indicators.</li>
                            <li>Chip Display: Select convenient chip values (2, 5, 10, 50, 100, 500).</li>
                            <li>Switch Table: Easily switch to another game table.</li>
                            <li>Game History: Access your previous live bets.</li>
                            <li>Chat Box: Interact with the dealer and fellow Teenpatti 2020 players.</li>
                        </ul>

                        <h2>Payouts for Teenpatti 2020 on {domainName}</h2>
                        <p>Following are payouts for different bet types on {domainName}:</p>

                        <h3>Pay Odds</h3>
                        <p>The following table shows payouts for standard bets on Teenpatti 2020:</p>
                        <Table>
                            <tr>
                                <th>Result</th>
                                <th>Payoff</th>
                            </tr>
                            <tr>
                                <td>0.98:1</td>
                                <td>Tie</td>
                            </tr>
                            <tr>
                                <td>50:1</td>
                                <td>Pair</td>
                            </tr>
                        </Table>

                        <h3>Pair + Payout</h3>
                        <p>The pair + bet has the following payouts for Teenpatti 2020 on {domainName}:</p>
                        <Table>
                            <tr>
                                <th>Result</th>
                                <th>Payoff</th>
                            </tr>
                            <tr>
                                <td>50:1</td>
                                <td>Three-of-a-kind AAA</td>
                            </tr>
                            <tr>
                                <td>40:1</td>
                                <td>Three-of-a-kind</td>
                            </tr>
                            <tr>
                                <td>30:1</td>
                                <td>Straight Flush</td>
                            </tr>
                            <tr>
                                <td>6:1</td>
                                <td>Straight</td>
                            </tr>
                            <tr>
                                <td>3:1</td>
                                <td>Flush</td>
                            </tr>
                            <tr>
                                <td>1:1</td>
                                <td>Pair</td>
                            </tr>
                        </Table>

                        <h3>6 Card Bonus Payout</h3>
                        <p>The 6 card bonus bet has the following payouts for Teenpatti 2020 on {domainName}:</p>
                        <Table>
                            <tr>
                                <th>Result</th>
                                <th>Payoff</th>
                            </tr>
                            <tr>
                                <td>1000:1</td>
                                <td>Royal Flush</td>
                            </tr>
                            <tr>
                                <td>200:1</td>
                                <td>Straight Flush</td>
                            </tr>
                            <tr>
                                <td>100:1</td>
                                <td>Four of a kind</td>
                            </tr>
                            <tr>
                                <td>20:1</td>
                                <td>Full House</td>
                            </tr>
                            <tr>
                                <td>15:1</td>
                                <td>Flush</td>
                            </tr>
                            <tr>
                                <td>10:1</td>
                                <td>Straight</td>
                            </tr>
                            <tr>
                                <td>7:1</td>
                                <td>Three of a kind</td>
                            </tr>
                        </Table>

                        <h2>Why {domainName} Is the Ultimate Destination for Playing Teenpatti 2020?</h2>
                        <p>{domainName} is the first choice to play Teenpatti 2020 for online casino gaming enthusiasts for various reasons. Here we have listed all the reasons why {domainName} is the best platform to play Teenpatti 2020:</p>
                        <ul>
                            <li>The platform offers an ultimate and seamless gaming experience.</li>
                            <li>Generous bonuses and rewards are up for grabs on {domainName}</li>
                            <li>Players can take advantage of the bonuses like the welcome bonus, referral bonus, and more.</li>
                            <li>A round-the-clock customer service team ensures that all your queries and issues are resolved immediately.</li>
                        </ul>
                        <p>Step into Teenpatti 2020 and embark on a journey of skill, strategy, and excitement like never before.</p>
                        <h2>FAQs about Teenpatti 2020</h2>
                        <ul>
                            <li><strong>Can I play Teenpatti 2020 on {domainName} for free?</strong>Yes. {domainName} offers the option to play for free in demo mode. It lets you practice and learn about the game before playing with real money.</li>
                            <li><strong>Is Teenpatti 2020 suitable for beginners?</strong>The game caters to players of all skill levels, including beginners. The game is easy to understand.</li>
                        </ul>


                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(Teenpatti2020)) ;

const Table = styled.table`
    border-collapse: collapse;

    tr, td, th {
        border: ${theme('palette.SeoFooterContentTableBorder')};
        border-collapse: collapse;
        text-align: center;
        padding: 10px;
    }
`;