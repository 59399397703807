import React, { createContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import Tabs from './casino-tab';
import AllGames from './all-games';
// import  casinoGameList1  from "./data";
import Intl from '../../common/Intl'
import { withAppContext } from 'src/store/initAppContext';
import { useLocation, useParams } from "react-router-dom";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import BlackjackSEO from "src/components/SEOComponents/BlackjackSEO";
import RouletteSEO from "src/components/SEOComponents/RouletteSEO";
import DragontigerSEO from "src/components/SEOComponents/DragontigerSEO";
import BaccaratSEO from "src/components/SEOComponents/BaccaratSEO";
import gameData from "src/static/game-data/casinogames.json";
import SEOContents from "src/components/SEOContents";
import EvolutionSEO from "src/components/SEOComponents/EvoSE0";
import SUPERNOWASEO from "src/components/SEOComponents/supernowaSEO";
// import BETGAMESSEO from "src/components/SEOComponents/bet-games";
import BetgamesSEO from "src/components/SEOComponents/betgames";
import EZUGISEO from "src/components/SEOComponents/ezugiSEO";
import Mojos7 from "src/components/SEOComponents/sevnMojos";
import SexyGamingSEO from "src/components/SEOComponents/sexyhgaming";
import ONLINECASINOSEO from "src/components/SEOComponents/onlineCasino";
import MACAWSEO from "src/components/SEOComponents/macaw";
import TVbetSEO from "src/components/SEOComponents/TVbetseo";
import SuperspadeSEO from "src/components/SEOComponents/superspadeseo";
import AgSEO from "src/components/SEOComponents/AgSeo";
// import { Helmet } from "react-helmet";
import LightningRouletteSEO from "src/components/SEOComponents/LightningRouletteSEO";
import FunkyTimeSEO from "src/components/SEOComponents/FunkyTimeSEO";
import CrazyTimeSEO from "src/components/SEOComponents/CrazyTimeSEO";
import TeenpattiSEO from "src/components/SEOComponents/TeenpattiSEO";
import SevenUpDown from "src/components/SEOComponents/SevenUpDown";
import AndarBaharKM46 from "src/components/SEOComponents/AndarBaharKM46";
import BonusDice46 from "src/components/SEOComponents/BonusDiceFun88";
import CoinToss46 from "src/components/SEOComponents/CoinTossF88";
import Colorgame46 from "src/components/SEOComponents/Colorgame46";
import Jhandimunda46 from "src/components/SEOComponents/Jhandimunda46";
import TripleChance46 from "src/components/SEOComponents/TripleChange46";
import AGseo46 from "src/components/SEOComponents/AGseo46";
import Skywardf88 from "src/components/SEOComponents/Skywardf88";
import Lucky7f88 from "src/components/SEOComponents/Lucky7f88";
import Betgamesf88 from "src/components/SEOComponents/Betgamesf88";
import Crazytimef88 from "src/components/SEOComponents/Crazytimef88";
import Dragontigerf88 from "src/components/SEOComponents/Dragontiger46";
import Funkytime88 from "src/components/SEOComponents/Funkytime88";
import HindiRoulettef88 from "src/components/SEOComponents/HindiRoulettef88";
import LightningDicef88 from "src/components/SEOComponents/LightningDicef88";
import LightningRoulettef88 from  "src/components/SEOComponents/LighningRoulettef88";
import MonopolyBigballerF88 from "src/components/SEOComponents/MonopolyBigballerF88";
import MonolpolyLivef88 from "src/components/SEOComponents/MonolpolyLivef88";
import RouletteF88 from "src/components/SEOComponents/RouletteF88";
import AndarbaharF88 from "src/components/SEOComponents/AndarbaharF88";
import TeenpattiF88 from "src/components/SEOComponents/TeenpattiF88";
import XXXtremeRoluetteF88 from "src/components/SEOComponents/XXXtremeRoluetteF88";
import EzugiRouletteF88 from "src/components/SEOComponents/EzugiRouletteF88";
import SuperSpadeFun88 from "src/components/SEOComponents/SuperSpadeFun88";
import TeenPatti2020Fun88 from "src/components/SEOComponents/TeenPatti2020Fun88";
import AndarBaharFun88 from "src/components/SEOComponents/AndarBaharFun88";
import AmericanRouletteFun88 from "src/components/SEOComponents/AmericanRouletteFun88";
import EvolutionBaccaratFun88 from "src/components/SEOComponents/EvolutionBaccaratFun88";
import EvolutionBlackjackSeoFun88 from "src/components/SEOComponents/EvoBlackjack";
import EvolutionDragontigerfun88 from "src/components/SEOComponents/EvolutionDragontigerfun88";
import DreamCatherf88 from "src/components/SEOComponents/DreamCatherf88";
import EvolutionTeenpattiFun88 from "src/components/SEOComponents/EvolutionTeenpattiFun88";
import EZUGIDragonTigerFun88 from "./EZUGIDragonTigerFun88";
import EZUGIoneDayTeenPattiFun88 from "./EZUGIoneDayTeenPattiFun88";
import Speedy7Fun88 from "src/components/SEOComponents/Speedy7Fun88";
import DiceDuelFun88 from "src/components/SEOComponents/DiceDuelFun88";
import SattaMatkaFun88 from "src/components/SEOComponents/SattaMatkaFun88";
import PlayTechFun88 from "src/components/SEOComponents/PlayTechFun88";
// Provider Icons for Live Casino, Slot Games, Bingo, Teenpatti---------------------------------
export const allIcon = `${'/static/media/AllProviderIcon.ab512083.png'}`;
export const netentIcon = `${'/static/media/netent.png'}`;
// export const betsoftIcon = `${'/static/media/Betsofticon.png'}`;
export const tvBetIcon = `${'/static/media/tvbet_icon.png'}`;
export const superSpadeIcon = `${'/static/media/superspade.png'}`;
export const ezugiIcon = `${'/static/media/ezugi_icon.png'}`;
export const evolutionIcon = `${'/static/media/evolution_icon.png'}`;
export const bettvIcon = `${'/static/media/bettv.png'}`;
export const agIcon = `${'/static/media/amazing_gaming.png'}`;
export const supernowaIcon = `${'/static/media/supernowa.png'}`;
export const spribeIcon = `${'/static/media/spribe_icon.png'}`;
export const sexygamingIcon = `${'/static/media/sexygamingIcon.png'}`
const kingmkersicon = `${'/static/media/kingmkersicon.png'}`;
const mplayicon = `${'/static/media/mplayicon.png'}`;
const macawicon = `${'/static/media/macawicon.png'}`;
const vivogamingIcon = `${'/static/media/vivogaming.png'}`;
const IndiaLotterygamingIcon = `${'/static/media/indiaLotterygaming.png'}`;


//Macaw Images
// const hotbaloon = `${'/static/common/media/hotBaloon.png'}`
// const hotbaloonHindi = `${'/static/common/media/hotBaloonHindi.png'}`
// const macludo = `${'/static/common/media/macawMacludo.png'}`
// const macludoHindi = `${'/static/common/media/macawMacludoHindi.png'}`
// const hotbaloon = `${'/static/common/media/HotBallonNew.jpg'}`
// const neon = `${'/static/common/media/neon.jpg'}`
// const macludo = `${'/static/common/media/MacLudoNew.jpg'}`

const moj = `${'/static/media/7mojos.png'}`
let ALLGAMES = <Intl langKey="GAME@ALL" />;
// let NETENT = <Intl langKey="GAME@NETENT"/>;
// let BETSOFT = <Intl langKey="GAME@BETSOFT" />;
let AG = <Intl langKey="GAME@AG" />;
let SUPERSPADE = <Intl langKey="GAME@SUPERSPADE" />;
let EVOGAMES = <Intl langKey="GAME@EVOGAMES" />;
let BETGAMES = <Intl langKey="GAME@BETGAMES" />;
let EZUGI = <Intl langKey="GAME@EZUGI" />;
let TVBET = <Intl langKey="GAME@TVBET" />;
let SUPERNOWA = <Intl langKey="GAME@SUPERNOWA" />;
// let SPRIBE = <Intl langKey="GAME@SPRIBE" />;
let SEXYGAMING = <Intl langKey="GAME@SEXYGAMING" />;
let PRAGMATICPLAY = <Intl langKey="GAME@PRAGMATICPLAY" />;
// let SEXYGAMING = <Intl langKey="GAME@SEXYGAMING"/>;

const Language = cookieUtil.get(ECookieName.COOKIE_LANG);


// let KINGMAKER = <Intl langKey="GAME@KINGMAKER" />
let MPLAY = <Intl langKey="GAME@MPLAY" />;
let MOJOS = <Intl langKey='GAME@7MOJO' />;
let MACAW = <Intl langKey="GAME@MACAW" />;
let VIVO = <Intl langKey="GAME@VIVO" />;
let INDIALOTTERY = <Intl langKey="GAME@INDIALOTTERY" />;


const providerList = ['all','evolution', 'ezugi','ag', 'betgames', 'tvbet', 'super-spade',  'supernowa', 'mplay',  '7mojos', 'macaw', 'vivo', 'indialottery'];
const providerTab = [ALLGAMES, EVOGAMES, EZUGI, AG, BETGAMES, TVBET, SUPERSPADE, SUPERNOWA, MPLAY, MOJOS, MACAW, VIVO, INDIALOTTERY];

const providerIcons = [
    {
        id: 1,
        name: ALLGAMES,
        icon: allIcon
    },
    {
        id: 2,
        name: EVOGAMES,
        icon: evolutionIcon
    },
    {
        id: 3,
        name: EZUGI,
        icon: ezugiIcon
    },
    {
        id: 4,
        name: AG,
        icon: agIcon
    },
    {
        id: 5,
        name: BETGAMES,
        icon: bettvIcon
    },
    {
        id: 6,
        name: TVBET,
        icon: tvBetIcon
    },
    {
        id: 7,
        name: SUPERSPADE,
        icon: superSpadeIcon
    },
    {
        id: 8,
        name: SUPERNOWA,
        icon: supernowaIcon
    },
    // {
    //     id:12,
    //     name: SPRIBE,
    //     icon: spribeIcon
    // },
    {
        id: 9,
        name: MPLAY,
        icon: mplayicon
    },
    {

        id: 10,
        name: MOJOS,
        icon: moj
    },
    {
        id: 11,
        name: MACAW,
        icon: macawicon
    },
    // {
    //     id: 12,
    //     name: KINGMAKER,
    //     icon: kingmkersicon
    // },
    // {
    //     id: 13,
    //     name: SEXYGAMING,
    //     icon: sexygamingIcon
    // },
    {
        id: 12,
        name: VIVO,
        icon: vivogamingIcon
    },
    {
        id: 13,
        name: INDIALOTTERY,
        icon: IndiaLotterygamingIcon
    }
]

function CasinoTabGames(props) {
    const { app: { brandId }, theme: { config } } = props
    const { showSeoFooterInsidePages, showSEOfooterForYolo,showSeofooterFun88 } = config
    const location = useLocation();
    const getLastqueryString = location.pathname.split("/").pop();
  

    const [casinoGames, setCasinoGames] = useState(gameData);
    const [filteredData, setfilteredData] = useState(gameData);
    const [search, setSearch] = useState('');
    const { provider, id } = useParams();

    // const param = useParams();
    // console.log('AAAAA-param',param);
  

    //const navigate = useNavigate()

    // useEffect(() => {
    //     // gameData(Language);
    //     // Now Lazy load game data 
    //     // import('src/static/game-data/casinogames.json').then(data => data.default).then(data => {
    //     // setCasinoGames(data);
    //     // if (!id) {
    //     //     setfilteredData(casinoGames)
    //     // }
    //     // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))



    //     //I am removing it becacuse in iexchange-frontend-desktop repo it is not there. if needed, we can enable 
    //     // window.scrollTo(0,0)
    //     // console.log('hiostory', history.location.searchText)
    //     // if(history.location.searchText !== undefined) {
    //     //     searchData(history.location.searchText)
    //     // }
    // }, [])



    const searchData = (val) => {
        setSearch(val);
    
        if (val !== "") {
            let _val = val && val.replace(/[\_\-\s]/gi, '');
            const filteredItems = casinoGames.filter((item) => {
                const { game = '', catagoryName = '', type = '', slug = '' } = item;
        
                // Normalize the fields by removing unwanted characters and converting to lowercase
                const normalizedGame = game.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedCategoryName = catagoryName.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedType = type.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedSlug = slug.replace(/[\_\-\s]/gi, '').toLowerCase();
        
                // Check if the cleaned-up field values start with the normalized search value
                return (
                    normalizedGame.includes(_val) || 
                    normalizedCategoryName.includes(_val) || 
                    normalizedType.includes(_val) || 
                    normalizedSlug.includes(_val)
                );
            

                // Clean up search value and item fields for better comparison
                // let type = item.type.toLowerCase() && item.type.replace(/[\_\-\s]/gi, '');
                // let slug = item.slug ? item.slug.replace(/[\_\-\s]/gi, '') : '';
                // let category = item.catagoryName ? item.catagoryName.replace(/[\_\-\s]/gi, '') : '';

    
                // // Check if either type or slug matches the search value
                // if (type.toLowerCase().includes(_val.toLowerCase()) || slug.toLowerCase().includes(_val.toLowerCase())) {
                //     return item;
                // }
                // else if ((item.game.replace(/[\_\-\s]/gi, '').toLowerCase()).startsWith(_val.toLowerCase())) {
                //     //searchOn = 'catagoryName'
                //     return item;
                //   }
                //   else if ((category.toLowerCase()).startsWith(_val.toLowerCase())) {
                //     //searchOn = 'catagoryName'
                //     return item;
                //   }
                //  else {
                //     const {platformCode, ...rest } = item;
                //     console.log('AAAAA-rest',rest);
                //     console.log('AAAAAA-',Object.values(rest).join('').toLowerCase().includes(val.toLowerCase()))
                //     // Fallback: Search through all other fields in the object
                //     return Object.values([platformCode]).join('').toLowerCase().includes(val.toLowerCase());
                // }
            });
    
            setfilteredData(filteredItems);
        } else {
            // Reset to original data when search input is empty
            setfilteredData(casinoGames);
        }
    };
    
    

    const budgetData = () => {

    }


    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247club.com';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
      }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }

    const _id = provider &&  id ? id : provider;


    return (
        <>
            {
                _id && (
                    <SEOContents
                        pageName={
                            _id === "evolution" ? "evolution"
                                : _id === "supernowa" ? "supernowa"
                                : _id === "ezugi"  ? "ezugi"
                                : _id === "7mojos" ? "7MOJOS"
                                : _id === "sexy-gaming" ? "sexygaming"
                                : _id === 'macaw' ? 'macaw'
                                : _id === 'tvbet' ? 'tvbet'
                                : _id === 'super-spade' ? 'superspade'
                                : _id === 'ag'  ? 'ag'
                                : _id === 'live-blackjack'  ? "live-blackjack"
                                : _id === 'live-roulette' ? "live-roulette"
                                : _id === 'live-baccarat' ? "live-baccarat"
                                : _id === 'betgames' ? "betgames"
                                : _id === 'crazytime' ? "crazytime" 
                                : _id === 'crazy-time' ? "crazytime" 
                                : _id === 'lightning-roulette' ? "lightningroulette" 
                                : _id === 'monopoly-live' ? "monopolylive"  
                                : _id === 'funky-time' ? "funkytime"
                                : _id === 'lightning-dice' ? "lightningdice"
                                : _id === 'macludo' ? 'macludo'
                                : _id === 'bingo' ? 'bingo'
                                : _id === 'evo' ? 'evo'
                                : _id === 'dragon-tiger' ? (provider ==='evolution'? 'evodragonTiger' : 'dragonTiger')
                                : _id === 'first person blackjack' ? 'First-Person-Blackjack'
                                : _id === 'teen-patti' && provider ==='evolution' ? 'evo-teenpatti'
                                : _id === 'teenpatti 20 20' ? 'teenpatti2020-2020'
                                : _id === 'andar-bahar' && provider ==='kingmaker' ? 'kingmaker-andarbahar'
                                 : _id === 'andar-bahar' && provider ==='ezugi' ? 'ezugi-andarbahar'
                                : _id === 'andar-bahar' && provider !=='kingmaker' ? 'andar-bahar'
                                : _id === 'blackjack' && provider ==='ezugi' ? 'ezugiBlackjack'
                                : _id === 'dream-catcher' ? 'dream-catcher'
                                : _id === 'hi-lo' ? 'hi-lo'
                                : _id === 'roulette' && provider ==='ezugi' ? 'ezugi-roulette'
                                : _id === 'teen-patti' && provider ==='ezugi' ? 'ezugi-teenpatti'
                                : _id === 'teen-patti-20-20' && provider ==='super-spade' ? 'sup-teenpatti2020'
                                : _id
                        }
                    />
                )
            }
            {
                !_id &&  <SEOContents pageName="onlineCasinoGames" />

            }
            <CasinoWrapper>
                <TabListSection>
                    <Tabs
                        searchText={search}
                        providerIcons={providerIcons}
                        tabs={providerTab}
                        onSearch={searchData}
                        isTabsHidden={false}
                        budgetClick={budgetData}
                        minWidthInContent={'initial'}
                        providerList={providerList}
                        render={(index) => {
                            return (
                                <>
                                    {providerTab[index] === ALLGAMES && <AllGames data={filteredData} />}
                                    {providerTab[index] === AG && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'ag';
                                    })} />}
                                    {providerTab[index] === SUPERSPADE && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'super-spade';
                                    })} />}

                                    {providerTab[index] === EVOGAMES && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'evolution';
                                    })} />}

                                    {providerTab[index] === BETGAMES && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'betgames';
                                    })} />}
                                    {providerTab[index] === EZUGI && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'ezugi';
                                    })} />}
                                    {providerTab[index] === TVBET && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'tvbet';
                                    })} />}

                                    {providerTab[index] === SUPERNOWA && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'supernowa';
                                    })} />}
                                    {/* {providerTab[index] === SPRIBE && <AllGames data={filteredData.filter((element, index) => {
                                    return element.type.toLowerCase() === 'SPRIBELIVECASINO';
                                })} />} */}
                                    {providerTab[index] === SEXYGAMING && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'sexygaming';
                                    })} />}
                                    {providerTab[index] === PRAGMATICPLAY && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'pragmaticplay';
                                    })} />}
                                    {providerTab[index] === MPLAY && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'mplay';
                                    })} />}
                                    {providerTab[index] === MOJOS && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === '7mojos';
                                    })} />}
                                    {providerTab[index] === MACAW && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'macaw';
                                    })} />}
                                    {/* {providerTab[index] === KINGMAKER && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'kingmaker';
                                    })} />} */}
                                    {providerTab[index] === VIVO && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'vivo';
                                    })} />}
                                    {providerTab[index] === INDIALOTTERY && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'indialottery';
                                    })} />}
                                </>
                            );
                        }} />
                </TabListSection>


            </CasinoWrapper> 
         
            {
                getLastqueryString === 'live-blackjack' && showSeoFooterInsidePages ? <BlackjackSEO /> :
                getLastqueryString === 'live-roulette' && showSeoFooterInsidePages ? <RouletteSEO /> :
                getLastqueryString == 'evolution' && showSEOfooterForYolo ? <EvolutionSEO /> :
                _id == 'betgames' && showSEOfooterForYolo ? <BetgamesSEO /> :
                _id == 'supernowa' && showSEOfooterForYolo ? <SUPERNOWASEO /> :
                _id === 'sexy-gaming' && showSEOfooterForYolo ? <SexyGamingSEO /> :
                (provider === 'ezugi' && getLastqueryString === 'dragon-tiger' && showSeofooterFun88) ? <EZUGIDragonTigerFun88 /> :
                (provider === 'ezugi' && getLastqueryString === 'one-day-teen-patti' && showSeofooterFun88) ? <EZUGIoneDayTeenPattiFun88 /> :
                (getLastqueryString === 'ezugi' && showSEOfooterForYolo && (brandId !== 46)) ? <EZUGISEO /> :
                _id === '7MOJOS' && showSEOfooterForYolo ? <Mojos7 /> :
                _id === 'macaw' && showSEOfooterForYolo ? <MACAWSEO /> :
                _id === 'tvbet' && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <TVbetSEO /> :
                _id === 'super-spade' && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <SuperspadeSEO /> :
                _id === "ag" && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <AgSEO /> :
                ((provider === 'evolution' && getLastqueryString === 'dream-catcher') && showSeofooterFun88) ? <DreamCatherf88 /> :
                ((provider === 'evolution' && getLastqueryString === 'dragon-tiger') && showSeofooterFun88) ? <EvolutionDragontigerfun88 /> :
                ((provider === 'evolution' && getLastqueryString === 'funky-time') && showSeofooterFun88) ? <Funkytime88 /> :
                ((getLastqueryString === 'dragon-tiger') && showSeofooterFun88) ? <Dragontigerf88 /> :
                getLastqueryString === 'dragon-tiger' && showSeoFooterInsidePages ? <DragontigerSEO /> :
                getLastqueryString === 'live-baccarat' && showSeoFooterInsidePages ? <BaccaratSEO /> :
                ((provider === 'evolution' && getLastqueryString === 'teen-patti') && showSeofooterFun88) ? <EvolutionTeenpattiFun88 /> :
                ((getLastqueryString === 'teen-patti') && showSeofooterFun88) ? <TeenpattiF88 /> :
                (getLastqueryString === 'teen-patti' && showSEOfooterForYolo && (brandId !== 46)) ? <TeenpattiSEO /> :
                showSEOfooterForYolo && getLastqueryString === 'live-casino' ? <ONLINECASINOSEO /> :
                ((getLastqueryString === 'lightning-roulette') && showSeofooterFun88) ? <LightningRoulettef88 /> :
                showSEOfooterForYolo && getLastqueryString === 'lightning-roulette' ? <LightningRouletteSEO /> :
                showSEOfooterForYolo && getLastqueryString === 'funky-time' && (brandId == 39 || brandId == 40) ? <FunkyTimeSEO /> : 
                showSEOfooterForYolo && getLastqueryString === 'crazy-time' && (brandId == 39 || brandId == 40) ? <CrazyTimeSEO /> :
                ((getLastqueryString === '7-up-7-down') && showSeofooterFun88) ? <SevenUpDown /> :
                ((provider === 'kingmaker' && getLastqueryString === 'andar-bahar') && showSeofooterFun88) ? <AndarBaharKM46 /> :
                ((provider === 'kingmaker' && getLastqueryString === 'bonus-dice') && showSeofooterFun88) ? <BonusDice46 /> :
                ((provider === 'kingmaker' && getLastqueryString === 'coin-toss') && showSeofooterFun88) ? <CoinToss46 /> :
                ((provider === 'kingmaker' && getLastqueryString === 'color-game') && showSeofooterFun88) ? <Colorgame46 /> :
                ((provider === 'kingmaker' && getLastqueryString === 'jhandi-munda') && showSeofooterFun88) ? <Jhandimunda46 /> :
                ((provider === 'kingmaker' && getLastqueryString === 'triple-chance') && showSeofooterFun88) ? <TripleChance46 /> :
                ((getLastqueryString === 'teenpatti') && showSeofooterFun88) ? <TeenpattiSEO /> :
                ((getLastqueryString === "ag") && showSeofooterFun88) ? <AGseo46 /> :
                ((provider === "betgames" && _id === 'skyward') && showSeofooterFun88) ? <Skywardf88 /> :
                ((provider === "betgames" && _id === 'lucky-7') && showSeofooterFun88) ? <Lucky7f88 /> :
                ((provider === "betgames") && getLastqueryString === 'speedy-7') && showSeofooterFun88 ? <Speedy7Fun88 /> :
                ((provider === "betgames") && getLastqueryString === 'dice-duel') && showSeofooterFun88 ? <DiceDuelFun88 /> :
                ((provider === "betgames") && getLastqueryString === 'satta-matka') && showSeofooterFun88 ? <SattaMatkaFun88 /> :
                ((provider === "betgames") && showSeofooterFun88) ? <Betgamesf88 /> :
                ((getLastqueryString === "playtech") && showSeofooterFun88) ? <PlayTechFun88 /> :
                ((getLastqueryString === 'crazy-time') && showSeofooterFun88) ? <Crazytimef88 /> :
                ((getLastqueryString === 'hindi-lightning-roulette') && showSeofooterFun88) ? <HindiRoulettef88 /> :
                ((getLastqueryString === 'lightning-dice') && showSeofooterFun88) ? <LightningDicef88 /> :
                ((getLastqueryString === 'monopoly-big-baller') && showSeofooterFun88) ? <MonopolyBigballerF88 /> :
                ((getLastqueryString === 'monopoly-live') && showSeofooterFun88) ? <MonolpolyLivef88 /> :
                ((getLastqueryString === 'roulette' && provider === 'evolution') && showSeofooterFun88) ? <RouletteF88 /> :
                ((getLastqueryString === 'roulette' && provider === 'ezugi') && showSeofooterFun88) ? <EzugiRouletteF88 /> :
                ((getLastqueryString === 'super-andar-bahar') && showSeofooterFun88) ? <AndarbaharF88 /> :
                ((getLastqueryString === 'xxxtreme-lightning-roulette') && showSeofooterFun88) ? <XXXtremeRoluetteF88 /> :
                ((getLastqueryString === 'super-spade') && showSeofooterFun88) ? <SuperSpadeFun88 /> :
                ((getLastqueryString === 'teen-patti-20-20') && showSeofooterFun88) ? <TeenPatti2020Fun88 /> :
                ((getLastqueryString === 'andar-bahar') && showSeofooterFun88) ? <AndarBaharFun88 /> :
                ((getLastqueryString === 'american-roulette' && provider === 'evolution') && showSeofooterFun88) ? <AmericanRouletteFun88 /> :
                ((getLastqueryString === 'baccarat' && provider === 'evolution') && showSeofooterFun88) ? <EvolutionBaccaratFun88 /> :
                ((getLastqueryString === 'blackjack' && provider === 'evolution') && showSeofooterFun88) ? <EvolutionBlackjackSeoFun88 /> :
                <></>
            }
        </>
    )
}


export default withAppContext(withTheme(CasinoTabGames));

const CasinoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

const TabListSection = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;
